@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/nunito-sans--regular.woff2') format('woff2'),
        url('../fonts/nunito-sans--regular.woff') format('woff');
}

$spa-ui-font-family: 'Nunito Sans';
$spa-ui-font-weight-400: 400;
$spa-ui-font-weight-600: 600;
$spa-ui-font-weight-700: 700;
$spa-ui-font-size-12: 12;
$spa-ui-font-size-14: 14;
$spa-ui-font-size-16: 16;
$spa-ui-font-size-18: 18;
$spa-ui-font-size-20: 20;
$spa-ui-font-size-24: 24;
$spa-ui-font-size-28: 28;
$spa-ui-font-size-32: 32;
$spa-ui-font-size-40: 40;
$spa-ui-font-size-48: 48;
$spa-ui-font-size-56: 72;
$spa-ui-font-size-64: 64;
$spa-ui-font-size-72: 72;
$spa-ui-font-line-height-18: 18;
$spa-ui-font-line-height-20: 20;
$spa-ui-font-line-height-24: 24;
$spa-ui-font-line-height-28: 28;
$spa-ui-font-line-height-32: 32;
$spa-ui-font-line-height-36: 36;
$spa-ui-font-line-height-40: 40;
$spa-ui-font-line-height-48: 48;
$spa-ui-font-line-height-64: 64;
$spa-ui-font-line-height-72: 72;
$spa-ui-font-line-height-80: 80;
$spa-ui-font-line-height-104: 104;