// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import "./assets/tokens/fonts.scss";
@import "./assets/tokens/colors.scss";
@import "./assets/tokens/spacing.scss";
@import "./assets/tokens/fonts.scss";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$spaesta-analysis-primary: mat.define-palette(mat.$indigo-palette);
$spaesta-analysis-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$spaesta-analysis-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$spaesta-analysis-theme: mat.define-light-theme((color: (primary: $spaesta-analysis-primary,
        accent: $spaesta-analysis-accent,
        warn: $spaesta-analysis-warn,
      ),
      typography: mat.define-typography-config($font-family: $spa-ui-font-family),
      density: 0));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($spaesta-analysis-theme);

/* You can add global styles to this file, and also import other style files */

/* You can add global styles to this file, and also import other style files */
html,
body,
.wrapper {
  height: 100vh;
  margin: 0;

}

.wrapper {
  padding: 10px;
}

body {
  background-color: #f6f7f8;
  margin: 0;
  font-family: $spa-ui-font-family !important;
}

html,
body,
.plyr--video {
  height: 100%;
  overflow-x: hidden;
}

video {
  height: 100%;
  width: 100%;
}

.p-0 {
  padding: 0 !important;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(0 0 0 / 20%);
  border-radius: 8px;
  background-clip: content-box;
  border: 2px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(0 0 0 / 40%);
  transition: 0s;
}

.initial-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: #{$spa-ui-spacing-32}px;
  height: #{$spa-ui-spacing-32}px;
  border-radius: 50%;
  background-color: #{$spa-ui-color-base-core-color-white};
  color: #10263a;
  font-weight: bold;
  font-size: #{$spa-ui-font-size-14}px;
}